import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container } from "reactstrap";
import Header from "./header/Header";
// import Customizer from './customizer/Customizer';
// import Sidebar from './sidebars/vertical/Sidebar';
import HorizontalHeader from "./header/HorizontalHeader";
import HorizontalSidebar from "./sidebars/horizontal/HorizontalSidebar";
import AppLogout from "../components/common/AppLogout";

const AdminLayout = () => {
  // const customizerToggle = useSelector((state) => state.customizer.customizerSidebar);
  const toggleMiniSidebar = useSelector(
    (state) => state.customizer.isMiniSidebar
  );
  //   const showMobileSidebar = useSelector((state) => state.customizer.isMobileSidebar);
  const topbarFixed = useSelector((state) => state.customizer.isTopbarFixed);
  const LayoutHorizontal = useSelector(
    (state) => state.customizer.isLayoutHorizontal
  );
  const isFixedSidebar = useSelector(
    (state) => state.customizer.isSidebarFixed
  );

  return (
    <main>
      <div
        className={`pageWrapper d-md-block   ${
          toggleMiniSidebar ? "isMiniSidebar" : ""
        }`}
      >
        <div className={`contentArea ${topbarFixed ? "fixedTopbar" : ""}`}>
          {/********header**********/}
          <AppLogout>
            {LayoutHorizontal ? <HorizontalHeader /> : <Header />}
            {LayoutHorizontal ? <HorizontalSidebar /> : <HorizontalSidebar />}
            {/********Middle Content**********/}

            <Container fluid className="p-3 admin_container">
              <div
                className={
                  isFixedSidebar && LayoutHorizontal ? "HsidebarFixed" : ""
                }
              >
                <Outlet />
              </div>
              {/* <Customizer className={customizerToggle ? 'showCustomizer' : ''} />
            {showMobileSidebar || customizerToggle ? <div className="sidebarOverlay" /> : ''} */}
            </Container>
          </AppLogout>
        </div>
      </div>
    </main>
  );
};

export default AdminLayout;
