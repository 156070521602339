import { createSlice } from '@reduxjs/toolkit';
import { createEncryptedAsyncThunk } from '../../Utility/APIUtils';

const initialState = {
  // creditAmount
  creditAmountSucess: false,
  creditAmountResponse: {
    error: false,
    message: '',
  },
  commonDeleteResponse: {
    status: false,
    message: '',
  },
  commonDeleteSucces: false,
  error: '',
  loading: false,
};

export const getNotification = createEncryptedAsyncThunk(
  'managementSlice/getNotification',
  'account/GetNotificationDetails',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      roles: [1, 2, 3, 4, 5, 6],
      serviceId: [],
      city: '',
      states: '',
      languages: '',
      startDate: null,
      endDate: null,
    }),
  },
);
export const CreateNotification = createEncryptedAsyncThunk(
  'managementSlice/CreateNotification',
  'account/CreateNotification',
  {
    prepareBody: (params) => ({
      userId: params.body.currentUser.userId,
      language: params.body.languageId,
      platform: params.body.platformId,
      role: params.body.roleId,
      services: params.body.servicesId,
      state: params.body.stateId,
      city: params.body.cityId,
      from_Date: params.body.fromDate,
      to_Date: params.body.toDate,
      images: [params.body.image],
      description: params.body.description,
    }),
  },
);
// export const getLoginHistory = createEncryptedAsyncThunk(
//   'managementSlice/getLoginHistory',
//   (params)=> `account/GetUserLoginLogs?userId=${
//     params.currentUser.userId
//   }&userLogId=${1}`,
//   {
//    method: 'GET'
//   },
// );
export const getLoginHistory = createEncryptedAsyncThunk(
  'managementSlice/getLoginHistory',
  'account/GetUserLoginLogs',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      userLogId: params.selectDistributor ? params.selectDistributor.value : 0,
      req_startDate: params.fromDate,
      req_endDate: params.toDate,
      ipAddress: '',
    }),
  },
);
export const getUserCredentialDetails = createEncryptedAsyncThunk(
  'managementSlice/getUserCredentialDetails',
  'UserRegistration/Get_User_Management',
  {
    prepareBody: (params) => ({
      userId: 0,
      roleId: params.body.roleID.value,
      flag: params.body.roleID.label,
    }),
  },
);
export const updateUserPassword = createEncryptedAsyncThunk(
  'managementSlice/updateUserPassword',
  'UserRegistration/UpdatePassword',
  {
    prepareBody: (params) => ({
      userID: params.modalData.UserID,
      username: params.userName,
      password: params.userPassword,
      mobileNo: params.modalData.Mobile,
    }),
  },
);
export const updateUserMpin = createEncryptedAsyncThunk(
  'managementSlice/updateUserMpin',
  'UserRegistration/SetMPIN',
  {
    prepareBody: (params) => ({
      userID: params.modalData.UserID,
      mpin: params.userPassword,
    }),
  },
);
export const getRetailerCustomer = createEncryptedAsyncThunk(
  'managementSlice/getRetailerCustomer',
  'UserRegistration/GetAgentWiseCustomer',
  {
    prepareBody: (params) => ({
      userId: params.body.setSelectDistributor.value,
      roleId: 0,
      flag: 'string',
    }),
  },
);
export const getDecryptedPassword = createEncryptedAsyncThunk(
  'managementSlice/getDecryptedPassword',
  'UserRegistration/Get_Password',
  {
    prepareBody: (params) => ({
      password: params.pass,
    }),
  },
);
export const getRetailerCustomerBeneficiary = createEncryptedAsyncThunk(
  'managementSlice/getRetailerCustomerBeneficiary',
  'UserRegistration/GetCustomerWiseBeni',
  {
    prepareBody: (params) => ({
      userId: 0,
      roleId: params.body.Cust_ID,
      flag: 'string',
    }),
  },
);

export const getComplaintReport = createEncryptedAsyncThunk(
  'managementSlice/getComplaintReport',
  'account/GetTicketMaster',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      ticketUserId: 0,
    }),
  },
);
export const getMenuList = createEncryptedAsyncThunk(
  'managementSlice/getMenuList',
  'Roles/GetUserRoleRightStatus',
  {
    prepareBody: (params) => ({
      userId: params.selectedBusiness.value,
      roleId: params.roleId,
    }),
  },
);
export const getUserwiseServiceList = createEncryptedAsyncThunk(
  'managementSlice/getUserwiseServiceList',
  'Service/GetUserServiceMaster',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      service_userId: params.selectedUser.value,
    }),
  },
);
export const updateUserServiceRights = createEncryptedAsyncThunk(
  'managementSlice/updateUserServiceRights',
  'Service/UpdateUserServiceMaster',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      serviceDetails: params.UpdatedServiceList,
    }),
  },
);
export const updateUserRights = createEncryptedAsyncThunk(
  'managementSlice/updateUserRights',
  'account/UpdateUserRoleRightStatus',
  {
    prepareBody: (params) => ({
      user_Id: 9,
      role_rights: params.menuList,
    }),
  },
);
export const complaintReply = createEncryptedAsyncThunk(
  'managementSlice/complaintReply',
  'account/UpdateTicket',
  {
    prepareBody: (params) => ({
      userId: params.modalData.createdBy,
      comp_id: params.modalData.compId,
      category: params.modalData.category,
      type: params.modalData.type,
      mobileNo: params.modalData.mobileNo,
      subject: params.modalData.subject,
      transaction_id: params.modalData.transactionId,
      description: params.reply,
    }),
  },
);
export const getLimitDetailsCustomer = createEncryptedAsyncThunk(
  "managementSlice/getLimitDetailsCustomer",
  "Service/GetUserDetailsByMobNo",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      mobNo: params.customerMobileNumber,
    }),
  }
);
export const getBankListDataAdmin = createEncryptedAsyncThunk(
  "managementSlice/getBankListDataAdmin",
  "bank/GetBankList",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      bankId: 0,
    }),
  }
);
export const addBankAdmin = createEncryptedAsyncThunk(
  'manageBankSlice/addBankAdmin',
  'Service/AddMasterBank',
  {
    prepareBody: (params) => ({
      userId: params.currentUser.userGuid,
      bankName: params.bankName,
      ifsc: params.ifsc,
      branch: params.branchName,
      address: params.branchAddress,
    }),
  },
);
export const UpdateBankAdmin = createEncryptedAsyncThunk(
  'manageBankSlice/UpdateBankAdmin',
  'Service/UpdateBankMaster',
  {
    prepareBody: (params) => ({
      userId: params.currentUser.userGuid,
      bankid: params.bankid,
      ifsc: params.ifsc,
      branchName: params.branchName,
      address: params.branchAddress,
    }),
  },
);

export const updateUserWiseTpin = createEncryptedAsyncThunk(
  "managementSlice/updateUserWiseTpin",
  "account/ChangeTpin",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      tpinUserId: params.userid,
      oldTpin: '',
      newTpin: params.newTpin,
      confirmTpin: params.newTpin,
    }),
  }
);
export const updateCustomerLimitSubmit = createEncryptedAsyncThunk(
  'managementSlice/updateCustomerLimitSubmit',
  'account/UpdateCustomerLimit',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      customer_mobile: params.customerMobileNumber,
      limit: params.selectSetLimitMobileNo,
    }),
  },
);
export const updateCustomerLimitSubmitAll = createEncryptedAsyncThunk(
  'managementSlice/updateCustomerLimitSubmitAll',
  'account/UpdateAllCustomerLimit',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      limit: params.selectSetLimitMobileNo,
    }),
  },
);
export const complaintDelete = createEncryptedAsyncThunk(
  'managementSlice/complaintDelete',
  'UserRegistration/DeleteComplaint',
  {
    prepareBody: (params) => ({
      com_ID: params.item.Com_ID,
      createdby: 0,
      remarks: 'string',
    }),
  },
);
export const managementSlice = createSlice({
  name: 'managementSlice',
  initialState,
  reducers: {
    creditAmountSuccess(state) {
      return { ...state, creditAmountSucess: true };
    },
    creditAmountFailed(state, action) {
      return { ...state, creditAmountResponse: { error: true, message: action.payload } };
    },
    FetchSuccess(state, action) {
      return { ...state, recharge: action.payload.Data[0] };
    },
    FetchFailed(state, action) {
      return { ...state, rechargeResponse: { status: true, message: action.payload } };
    },
    DeleteSuccess(state) {
      return { ...state, loading: false, commonDeleteSucces: true };
    },
    DeleteFailed(state, action) {
      return {
        ...state,
        fetchError: false,
        commonDeleteResponse: { status: true, message: action.payload },
      };
    },
    DeleteInitial(state) {
      return {
        ...state,
        commonDeleteSucces: false,
        commonDeleteResponse: { status: false, message: '' },
      };
    },
  },
});
export const {
  FetchSuccess,
  FetchFailed,
  DeleteSuccess,
  DeleteFailed,
  DeleteInitial,
  creditAmountSuccess,
  creditAmountFailed,
} = managementSlice.actions;
export default managementSlice.reducer;
